import Vue from "vue";
import { Component, Prop, Watch } from 'vue-property-decorator';
import Map from 'ol/Map';
import View from 'ol/View';
import { Projection, fromLonLat, transform } from "ol/proj";
import ImageLayer from "ol/layer/Image";
import Static from 'ol/source/ImageStatic.js';
import { getCenter } from "ol/extent";
import { ZoomToExtent, defaults } from 'ol/control';


@Component({})
export default class MapsEdit extends Vue {

  map: Map | undefined;

  mounted() {
    const image = new Image();
    const _this = this;
    image.src = '../maps/test-map.jpeg';
    image.setAttribute('crossorigin', 'anonymous');
    image.onload = function (e) {
      const extent = [0, 0, image.width, image.height];
      const projection = new Projection({
        code: 'test-image',
        units: 'pixels',
        extent: extent
      })
      _this.map = new Map({
        layers: [
          new ImageLayer({
            source: new Static({
              attributions: '',
              url: '../maps/test-map.jpeg',
              projection: projection,
              imageExtent: extent
            })
          })
        ],
        target: 'map',
        view: new View({
          projection: projection,
          center: getCenter(extent),
          zoom: 2,
          maxZoom: 4,
          minZoom: 2
          
        }),
        controls: defaults({
          zoom: true,
          rotate: true,
          attribution: true
        }).extend([
          
        ])
      })

    }




  }



}