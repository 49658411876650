<template>
  <div class="maps-edit">
    <div id="map" class="map" tabindex="0"></div>
  </div>
</template>

<style >
@import '~ol/ol.css';
.map {
  width: 100%;
  height: 100%;
}
#map:focus {
  outline: #4a74a8 solid 0.15em;
}
.maps-edit{
  height: 100%;
}
</style>

<script>
import MapsEdit from "./mapsEdit";
export default MapsEdit;
</script>
